import { boolean, string, object, optional, Infer } from "superstruct";

export const whatsappSchema = object({
  enable: boolean(),
  phone: string(),
  onFirstScreen: optional(boolean()),
  inDoctorBlock: optional(boolean()),
  start_page_text: optional(string()),
  form_page_text: optional(string()),
  doctor_text: optional(string()),
});

// Тип для WhatsappSchema
export type WhatsappSchema = Infer<typeof whatsappSchema>;
